import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const RetailFuelDistribution = () => {
    return (
        <Layout>
            <Seo title="Retail Fuel Distribution" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Retail Fuel Distribution</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body services-text d-flex flex-column">
                                    <p className="card-text">
                                        Our Energy Logistic presence is rapidly growing in East Africa where we roll out our Retail
                                        Fuel distribution network under the DC Energy banner. To service our distribution,  we also own
                                        two liquid bulk storage facilities in Uganda. We are a registered Oil Marketing Company (OMC)
                                        in Kenya, Uganda, Tanzania and Rwanda.
                                    </p>
                                    <div className="mb-3 fw-bold">
                                        <div>Premium Motor Spirit (PMS)</div>
                                        <div>Automotive Gas Oil (AGO)</div>
                                        <div>Kerosene (IK)</div>
                                        <div>LPG</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2">
                                <StaticImage
                                    className="mb-3 w-100"
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/service/eva.jpg"
                                    aspectRatio={16 / 9}
                                    width={640}
                                    layout="fixed"
                                />
                                <StaticImage
                                    className="mb-3 w-100"
                                    alt="Sales team"
                                    src="../../../images/service/evara2.jpg"
                                    aspectRatio={16 / 9}
                                    width={640}
                                    layout="fixed"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default RetailFuelDistribution
